import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Pages/Dashboard/Dashboard';
// import Dashboard_sandipan from './components/Pages/Dashboard/Dashboard_sandipan';
import Register from './components/Pages/Register/Register';
import Login from './components/Pages/Login/Login';
import ForgeotPswd from './components/Pages/Login/ForgeotPswd';
import Profile from './components/Pages/Profile/Profile';
import RegisterCourse from './components/Pages/Courses/RegisterCourse';
import EnrolledCourses from './components/Pages/Courses/EnrolledCourses';
import EnrolledCourseDtl from './components/Pages/Courses/EnrolledCourseDtl';
import CourseDetails from './components/Pages/Courses/CourseDetails';
import Logout from './components/Pages/Logout';
import Home from './components/Pages/Home/Home';
import PreExamstart from './components/Pages/Examstart/PreExamstart';
import PostExamstart from './components/Pages/Examstart/PostExamstart';
import FinalExamstart from './components/Pages/Examstart/FinalExamstart';
import Activity from './components/Pages/Activity/Activity';
// import IDECourse from './components/Pages/IDECourse/IDECourse';
import Gallery from './components/Pages/Gallery/Gallery';
import PermissionDenied from './components/Pages/Notpermit/PermissionDenied';
import ContentRead from './components/Pages/Courses/ContentRead';

function App() {
 
  return (
    <div className="App">      
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route exact path="/activity" element={<Activity />}/>
          <Route exact path="/courseDetails" element={<CourseDetails />}/>
          <Route exact path="/Gallery" element={<Gallery />}/>
          
          <Route exact path="/login" element={<Login />}/>
          <Route exact path="/forgeotPswd" element={<ForgeotPswd />}/>
          <Route exact path="/register" element={<Register />}/>
          <Route exact path="/dashboard" element={<Dashboard />}/>
          <Route exact path="/ContentRead" element={<ContentRead />}/>
          <Route exact path="/preExamStart" element={<PreExamstart />}/>
          <Route exact path="/postExamStart" element={<PostExamstart />}/>
          <Route exact path="/finalExamStart" element={<FinalExamstart />}/>
          <Route exact path="/registerCourse" element={<RegisterCourse />}/>
          <Route exact path="/enrolledCourses" element={<EnrolledCourses />}/>
          <Route exact path="/enrolledCourseDtl" element={<EnrolledCourseDtl />}/>
          <Route exact path="/profile" element={<Profile />}/>
          <Route exact path="/permissionDenied" element={<PermissionDenied />}/>
          <Route exact path="/logout" element={<Logout />}/>
        </Routes>
      </Router>
    </div>
  )
}
export default App;
