import React, { useState, useEffect } from 'react';
import logo from '../images/khf.png'
import '../css/style.css';
import { fetchCourses } from "../../crud/common.crud";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap-v5";

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}
  
  window.addEventListener("scroll", reveal);


const Header = () => {
    const loggedIn = localStorage.getItem('loggedin');
    const [courses, setCourses] = useState([]);
    useEffect(() => {
      fetchCourses().then(res => {
        setCourses(res.data);
      });
    },[]);

    const invokeCrsDtl = (slug) => {
      localStorage.setItem('courseDetailsSlug', JSON.stringify(slug));
    };
    return(
        <>
        
        { 
            loggedIn ? (
              <Navbar bg="light" expand="lg">
                <Container fluid className="navstyle">
                  <Navbar.Brand className="navbrand" href="/"><img className="img-fluid" src={logo}/></Navbar.Brand>
                  <Navbar.Toggle aria-controls="" />
                  <Navbar.Collapse id="">
                    <Nav
                      className=" justify-content-end text-center"
                      style={{ maxHeight: '350px', width:'100%'}}
                        >
                        <Nav.Link href="/dashboard">Dashboard</Nav.Link>
                        <Nav.Link href="/registerCourse">Register New</Nav.Link>
                        <Nav.Link href="/enrolledCourses">Enrolled Course</Nav.Link>
                        <Nav.Link href="/profile">Profile</Nav.Link>
                        <Nav.Link href="/logout">Logout</Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            ): (
              <Navbar className="top-animate" bg="light" expand="lg" style={{zIndex:'9999'}}>
                <Container fluid className="navstyle">
                  <Navbar.Brand className="navbrand" href="/"><img className="img-fluid" src={logo}/></Navbar.Brand>
                  <Navbar.Toggle aria-controls="" />
                  <Navbar.Collapse id="">                  
                    <Nav
                        className=" justify-content-end text-center"
                        style={{ maxHeight: '350px', width:'100%'}}
                        >
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/activity">Activity</Nav.Link>
                        <NavDropdown title="Courses" id="navbarScrollingDropdown">
                          {courses?.map(crs=>
                            <NavDropdown.Item href="/courseDetails" key={crs._id} onClick={(e)=>{invokeCrsDtl(crs.couseSlug)}}>
                              {crs.couseName}
                            </NavDropdown.Item>
                          )}
                        </NavDropdown>
                        <Nav.Link href="/Gallery">Gallery</Nav.Link>
                        <Nav.Link href="/#contact">Contact</Nav.Link>
                        <Nav.Link href="/login">Login</Nav.Link>
                        <Nav.Link href="/register">Register</Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            )
        }
        </>
        
    )
}
export default Header;