import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { logoutStudent } from "../../crud/common.crud";
// import logggout from '../images/logging-out.gif';
import { Spinner } from 'react-bootstrap-v5';

const Logout = () => {
    const [ redirect, setRedirect ]= useState(false);
    let studentDetails = JSON.parse(localStorage.getItem('student'));
    useEffect(() => {
        logoutStudent(studentDetails.studentEmail).then((res)=>{
            setRedirect(true);
            localStorage.clear();
        }).catch((err)=>{
            console.log(err);
        });
    }, [studentDetails.studentEmail]);
    

    if(redirect) {
        return <Navigate to="/" />;
    }
    return(
        <div style={{marginTop:'200px'}}>
            <div md={4} className="d-flex justify-content-center align-items-center">
            <span className="p-2 shadow ">
                <Spinner className="shadow" animation="border" variant="primary" />
                {/* <Spinner animation="grow" variant="primary" /> */}
            </span>
            </div>
        </div>
    )
}
export default Logout;