import React, {useState} from 'react';
import { ButtonGroup, ToggleButton, Row, Col } from 'react-bootstrap-v5';
import PropTypes from 'prop-types';

const Step2 = (props) => {
    const [radioValue, setRadioValue] = useState('');
    if (props.currentStep !== 2) {
        return null
    } 
  
    const radios = [
      { name: props.questionss[props.currentStep - 1].option1, value: 'a' },
      { name: props.questionss[props.currentStep - 1].option2, value: 'b' },
      { name: props.questionss[props.currentStep - 1].option3, value: 'c' },
      { name: props.questionss[props.currentStep - 1].option4, value: 'd' },
    ];
    const selectedVal = (event,val) => {
        setRadioValue(val);
        props.handleChange(event);
    }
    if (props.currentStep !== 2) {
      return null
    } 
  
    return(
        <div className="form-group">
            <label htmlFor="ans2" className='pb-3'><b>{props.questionss[props.currentStep - 1].question}</b></label>
            <Row>
                <ButtonGroup className="mb-2">
                    <Col className="" style={{width:'10%'}}>
                    {radios.map((radio, idx) => (
                    <ToggleButton
                        className="col-md-6 col-sm-6 col-xs-12 col-lg-6"
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant={(radioValue === radio.value) ? 'outline-primary' : 'light'}
                        name="ans2"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => selectedVal(e,e.currentTarget.value)}
                    >
                        {radio.name}
                    </ToggleButton>
                    ))}
                    </Col>
                </ButtonGroup>
            </Row>
        </div>
    );
}
export default Step2;

Step2.propTypes = {
  // You can declare that a prop is a specific JS type. By default, these
  // are all optional.
  questionss: PropTypes.arrayOf(PropTypes.object),
  currentStep: PropTypes.number,
  handleChange: PropTypes.func
}