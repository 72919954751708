import React, { useState, useEffect } from 'react';
import Header from '../../Header/Header';
import LoaderPage from '../LoaderPage';
// import moment from 'moment';
import { updateStudent,getProfile } from "../../../crud/common.crud";
import dp from '../../images/common_dp.png';
import { Card,Container,Row,Col,Form,Badge,ListGroup,ListGroupItem,Button } from 'react-bootstrap-v5';

const Profile = () => {
    let today = new Date();
    let dd = (today.getDate() < 10 ? '0' : '')+ today.getDate();                    
    let MM = ((today.getMonth() + 1) < 10 ? '0' : '')+ (today.getMonth() + 1);
    let datewe = today.getFullYear()+'-'+MM+'-'+dd;
    const studentStrg = JSON.parse(localStorage.getItem("student"));
    const [ studentName, setStudentName ] = useState('');
    const [ studentID, setStudentID ] = useState('');
    const [ isDataLoaded, setIsDataLoaded ] = useState(false);
    const [ studentgurdianName, setStudentgurdianName ] = useState('');
    const [ studentAddress, setStudentAddress ] = useState('');
    const [ dob, setdob ] = useState(datewe);
    const [ gender, setGender ] = useState('');
    const [ studentCity, setStudentCity ] = useState('');
    const [ studentPin, setStudentPin ] = useState(0);
    const [ studentEmail, setStudentEmail ] = useState('');
    const [ studentPhone, setStudentPhone ] = useState(0);
    const [ studentWa, setStudentWa ] = useState(0);
    const [ Qualification, setQualification ] = useState('');
    const [ studentMedRegNo, setStudentMedRegNo ] = useState('');
    const [ studentMedRegNoDoc, setStudentMedRegNoDoc ] = useState('');
    const [ areaofpractise, setAreaOfPractise ] = useState('');
    const [ purchaseddd, setPurchaseddd ] = useState(0);
    const [ completedd, setCompletedd ] = useState(0);
    
    const calculationsss = (val) => {
        let comCount = 0
        for(let c=0,t=val;c<t;c++) {
            let comCheck=true;
            for(let l=0,lc=val.enrolledCourses[c].modules.length;l<lc;l++) {
                if(!val[c].modules[l].finalStatus) { comCheck=false; }
            }
            if(comCheck) { comCount = comCount+1;}//all purchased courses
        }
        setPurchaseddd(val.length);
        setCompletedd(comCount);
    };
    useEffect(() => {
        getProfile(studentStrg.studentEmail).then(res => {
            // console.log(res.data);
            if(res.data.studentDob !==datewe) {
                let d = new Date(res.data.studentDob);
                let ddd = (d.getDate() < 10 ? '0' : '')+ d.getDate();                    
                let MMM = ((d.getMonth() + 1) < 10 ? '0' : '')+ (d.getMonth() + 1);
                let ddoobb = d.getFullYear()+'-'+MMM+'-'+ddd;
                setdob(ddoobb);
            }
            setStudentID(res.data.studentID);
            setStudentName(res.data.studentName);
            setStudentgurdianName(res.data.stdFHName);
            setStudentAddress(res.data.studentAddress.address);
            setGender(res.data.studentGender);
            setStudentCity(res.data.studentAddress.city);
            setStudentPin(res.data.studentAddress.pin);
            setStudentEmail(res.data.studentEmail);
            setStudentPhone(res.data.studentPhone.mobile);
            setStudentWa(res.data.studentPhone.whatsapp);
            setQualification(res.data.studentProfDtls.eduQualification);
            setStudentMedRegNo(res.data.studentProfDtls.medRegNo);
            setStudentMedRegNoDoc(res.data.studentProfDtls.medRegNo);
            setAreaOfPractise(res.data.studentProfDtls.practiceArea);
            setIsDataLoaded(true);
            calculationsss(res.data.enrolledCourses);
        })
    }, [studentStrg.studentEmail]);
    document.addEventListener("contextmenu",function(PreventRightClick) {
         PreventRightClick.preventDefault();
    });
    const handleSubmit=(e) => {
        e.preventDefault();  
        let stdDetails = {
            studentName:studentName,
            stdFHName:studentgurdianName,
            studentAddress: {
                address:studentAddress,
                city:studentCity,
                pin:studentPin,
            },            
            studentDob:dob,
            studentGender:gender, 
            studentEmail:studentEmail,
            studentPhone: {
                mobile:studentPhone,
                whatsapp:studentWa
            },
            studentProfDtls: {
                medRegNo:studentMedRegNo,
                practiceArea:areaofpractise,
                eduQualification:Qualification
            },
            // studentUpdated:today
        }   
        updateStudent( studentStrg.studentEmail,stdDetails ).then(()=>{
            window.location.reload();
        }).catch((err)=>{
            console.log(err);
        });
    }
    return (
        <>
            {!isDataLoaded? (
                <LoaderPage />
            ) : (
                <>
                    <Header />
                    <Container  className="bg-light py-3" 
                        style={{borderRadius:'35px', 
                        borderBottom:'10px solid #0fa0e5e8',marginTop:'10px',marginBottom:'20px', 
                        background: "linear-gradient(#fff, #5fd7e38c, #fff)",
                        boxShadow:'0px 0px 50px #0fa0e5e8'}}>
                        <Row>
                            <Col md={4} className="my-auto">
                                <Card style={{borderRadius:'15px', boxShadow:'2px 2px 20px #666'}}>
                                    <Card.Body>
                                        <Row className='text-center py-2'>
                                        <Col><Badge pill bg="danger">{studentID}</Badge></Col>
                                        <Col><Badge pill bg="success">Completed</Badge></Col>    
                                        </Row>
                                        <Row className="justify-content-md-center my-4">
                                        <img className="img-fluid rounded-circle shadow" 
                                            style={{width:'40%', border:'2px solid #0defcc', padding:'5px'}} 
                                            src={dp} 
                                            alt="username"
                                        />    
                                        </Row>
                                    <Card.Title className="text-center">{studentName}</Card.Title>
                                        <ListGroup className="list-group-flush border shadow rounded text-center">
                                            <ListGroupItem>Total Courses : <Badge pill bg="info">{purchaseddd}</Badge></ListGroupItem>
                                            <ListGroupItem>Completed : <Badge pill bg="success">{completedd}</Badge></ListGroupItem>
                                            <ListGroupItem><a href="#" style={{textDecoration:'none', color:'#000'}}>Download Your Certificate <i className="fa fa-trophy"></i></a></ListGroupItem>
                                        </ListGroup>
                                    </Card.Body>
                                    <Card.Footer>
                                    {/* <small className="text-muted">Last updated 3 mins ago</small> */}
                                    </Card.Footer>
                                </Card>
                            </Col> 
                            <Col md={8}>
                                <Card className="bg-transparent" style={{borderRadius:'15px', boxShadow:'2px 2px 20px #666'}}>
                                    <Card.Header className='fw-bold text-center'>Update Profile Information</Card.Header>
                                    <Card.Body>
                                        <Form onSubmit={handleSubmit}>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3" controlId="formName">
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control type="text" 
                                                            defaultValue={studentName}
                                                            onChange={(e)=>{setStudentName(e.target.value)}}  
                                                            placeholder="Name for the Student" />  
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    {studentgurdianName? (
                                                        <Form.Group className="mb-3" controlId="formFather">
                                                            <Form.Label>Father / Husband</Form.Label>
                                                            <Form.Control type="text"
                                                                defaultValue={studentgurdianName}
                                                                onChange={(e)=>{setStudentgurdianName(e.target.value)}}  
                                                                placeholder="Father / Husband Name" />  
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group className="mb-3 border border-2 rounded bg-danger text-white p-2 border-danger" controlId="formFather">
                                                            <Form.Label>Father / Husband</Form.Label>
                                                            <Form.Control type="text"
                                                                defaultValue={studentgurdianName}
                                                                onChange={(e)=>{setStudentgurdianName(e.target.value)}}  
                                                                placeholder="Father / Husband Name" />  
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                            </Row>
                                            {studentAddress? (
                                            <Form.Group className="mb-3" controlId="formAddress">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control type="text" 
                                                    defaultValue={studentAddress}
                                                    onChange={(e)=>{setStudentAddress(e.target.value)}}  
                                                    placeholder="Address of the Student" />  
                                            </Form.Group>
                                            ) : (
                                                <Form.Group className="mb-3 border border-2 rounded bg-danger text-white p-2 border-danger" controlId="formFather">
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Control type="text" 
                                                    defaultValue={studentAddress}
                                                    onChange={(e)=>{setStudentAddress(e.target.value)}}  
                                                    placeholder="Address of the Student" /> 
                                                    </Form.Group>
                                            )}
                                            <Row>
                                                <Col>
                                                    {(dob===datewe)?(
                                                    <Form.Group className="mb-3 border border-2 rounded bg-danger text-white p-2 border-danger" controlId="formBirth">
                                                    <Form.Label>Date Of Birth</Form.Label>
                                                    <Form.Control type="date" 
                                                        defaultValue={dob}
                                                        onChange={(e)=>{setdob(e.target.value)}} />  
                                                    </Form.Group>
                                                    ) : ( 
                                                    <Form.Group className="mb-3" controlId="formBirth">
                                                    <Form.Label>Date Of Birth</Form.Label>
                                                    <Form.Control type="date" 
                                                        defaultValue={dob}
                                                        onChange={(e)=>{setdob(e.target.value)}}/>  
                                                    </Form.Group>
                                                    )}
                                                </Col>
                                                <Col>
                                                {gender? (
                                                    <Form.Group className="mb-3" controlId="formGender">
                                                        <Form.Label>Gender</Form.Label>
                                                        <Form.Select
                                                            value={gender}
                                                            onChange={(e)=>{setGender(e.target.value)}} >
                                                                <option value="Male">Male</option>
                                                                <option value="Female">Female</option>
                                                                <option value="Trans">Trans. Gen.</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group className="mb-3 mb-3 border border-2 rounded bg-danger text-white p-2 border-danger" controlId="formGender">
                                                        <Form.Label>Gender</Form.Label>
                                                        <Form.Select
                                                            onChange={(e)=>{setGender(e.target.value)}} >
                                                                <option value="">Select Gender</option>
                                                                <option value="Male">Male</option>
                                                                <option value="Female">Female</option>
                                                                <option value="Trans">Trans. Gen.</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                )}
                                                </Col>
                                            </Row>
                                            <Form.Group className="mb-3">
                                                <Row>
                                                {studentCity? (
                                                    <div className="col-6">
                                                        <Form.Label>City</Form.Label>
                                                        <Form.Control type="text" 
                                                            defaultValue={studentCity}
                                                            onChange={(e)=>{setStudentCity(e.target.value)}}  
                                                            placeholder="City" />  
                                                    </div> 
                                                    ) : (
                                                        <div className="col-6 mb-3 border border-2 rounded bg-danger text-white p-2 border-danger">
                                                        <Form.Label>City</Form.Label>
                                                        <Form.Control type="text" 
                                                            defaultValue={studentCity}
                                                            onChange={(e)=>{setStudentCity(e.target.value)}}  
                                                            placeholder="City" />  
                                                    </div> 
                                                    )}
                                                {studentPin? (
                                                    <div className="col-6">
                                                        <Form.Label>Pin</Form.Label>
                                                        <Form.Control type="number" 
                                                            value={studentPin}
                                                            onChange={(e)=>{setStudentPin(e.target.value)}}  
                                                            placeholder="Pin" />  
                                                    </div>
                                                ) : (
                                                    <div className="col-6 mb-3 border border-2 rounded bg-danger text-white p-2 border-danger">
                                                        <Form.Label>Pin</Form.Label>
                                                        <Form.Control type="number" 
                                                            value={studentPin}
                                                            onChange={(e)=>{setStudentPin(e.target.value)}}  
                                                            placeholder="Pin" />  
                                                    </div>
                                                    )}
                                                </Row>
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formEmail">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email" 
                                                    defaultValue={studentEmail}
                                                    onChange={(e)=>{setStudentEmail(e.target.value)}}  
                                                    placeholder="Email Address" />  
                                            </Form.Group>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3" controlId="formPhone">
                                                        <Form.Label>Phone</Form.Label>
                                                        <Form.Control type="number" 
                                                            value={studentPhone}
                                                            onChange={(e)=>{setStudentPhone(e.target.value)}}  
                                                            placeholder="Phone Number" />  
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3" controlId="formPhone">
                                                        <Form.Label>WhatsApp No</Form.Label>
                                                        <Form.Control type="number" 
                                                            value={studentWa}
                                                            onChange={(e)=>{setStudentWa(e.target.value)}}  
                                                            placeholder="Whatsapp Number" />  
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {Qualification?(
                                                <Form.Group className="mb-3" controlId="formMedical">
                                                    <Form.Label>Professional Educational Qualification</Form.Label>
                                                    <Form.Control type="text" 
                                                        defaultValue={Qualification}
                                                        onChange={(e)=>{setQualification(e.target.value)}}  
                                                        placeholder="e.g. M.B.B.S, M.D, M.S" />  
                                                </Form.Group>
                                            ):(
                                                <Form.Group className="mb-3 border border-2 rounded bg-danger text-white p-2 border-danger" controlId="formMedical">
                                                    <Form.Label>Professional Educational Qualification</Form.Label>
                                                    <Form.Control type="text" 
                                                        defaultValue={Qualification}
                                                        onChange={(e)=>{setQualification(e.target.value)}}  
                                                        placeholder="e.g. M.B.B.S, M.D, M.S" />  
                                                </Form.Group>
                                            )}
                                            {areaofpractise?(
                                                        <Form.Group className="mb-3" controlId="formMedical">
                                                            <Form.Label>Area of Practice</Form.Label>
                                                            <Form.Control type="text" 
                                                                defaultValue={areaofpractise}
                                                                onChange={(e)=>{setAreaOfPractise(e.target.value)}}  
                                                                placeholder="beleghata, Chinnar park, newtown etc" />  
                                                        </Form.Group>
                                                    ):(
                                                        <Form.Group className="mb-3 border border-2 rounded bg-danger text-white p-2 border-danger" controlId="formMedical">
                                                            <Form.Label>Area of Practice</Form.Label>
                                                            <Form.Control type="text" 
                                                                defaultValue={areaofpractise}
                                                                onChange={(e)=>{setAreaOfPractise(e.target.value)}}  
                                                                placeholder="beleghata, Chinnar park, newtown etc" />  
                                                        </Form.Group>
                                                    )}
                                            <Row>
                                                <Col>
                                                {studentMedRegNo?(
                                                    <Form.Group className="mb-3" controlId="formMedical">
                                                        <Form.Label>Medical Registration No</Form.Label>
                                                        <Form.Control type="text" 
                                                            defaultValue={studentMedRegNo}
                                                            onChange={(e)=>{setStudentMedRegNo(e.target.value)}}  
                                                            placeholder="Medical Registration No." />  
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group className="mb-3 border border-2 rounded bg-danger text-white p-2 border-danger" controlId="formMedical">
                                                        <Form.Label>Medical Registration No</Form.Label>
                                                        <Form.Control type="text" 
                                                            defaultValue={studentMedRegNo}
                                                            onChange={(e)=>{setStudentMedRegNo(e.target.value)}}  
                                                            placeholder="Medical Registration No." />  
                                                    </Form.Group>
                                                )}
                                                </Col>
                                                <Col>
                                                    {studentMedRegNoDoc?(
                                                        <Form.Group className="mb-3" controlId="formMedical">
                                                            <Form.Label>Upload Medical Registration Certificate</Form.Label>
                                                            <Form.Control type="file" multiple /> 
                                                        </Form.Group>
                                                    ):(
                                                        <Form.Group className="mb-3 border border-2 rounded bg-danger text-white p-2 border-danger" controlId="formMedical">
                                                            <Form.Label>Upload Medical Registration Certificate</Form.Label>
                                                            <Form.Control type="file" multiple /> 
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Form.Group>
                                                <Button type="submit" className="btn btn-success btn-sm">Update Profile</Button>                                    
                                            </Form.Group>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>  
                        </Row>
                    </Container> 
                </>
            ) };
        
        </>
    )
}
export default Profile;