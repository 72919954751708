import React, { useState, useEffect } from 'react';
import Header from '../../Header/Header';
import { fetchCourses,fetchSingleCourseWithModule,registerNewCourse } from "../../../crud/common.crud";
import { Card, Container, Form, Row,Button, Modal } from 'react-bootstrap-v5';
import '../../css/selectedStyle.css';

const RegisterCourse = () => {
    const studentStrg = JSON.parse(localStorage.getItem("student"));
    const [isOpen, setIsOpen] = useState(false);
    const [courses, setCourses] = useState([]);
    // const [courseModules, setCourseModules] = useState([]);
    const [singleCourse, setSingleCourse] = useState({
        couseName: '',
        couseSlug: '',
        allModule: [],
        noSingleModule: false,
        coursePrice : {  
            corporatePrice : 0,
            selfPrice : 0
        },
        couseTenure: 0
    });
    const [finalPrice, setFinalPrice] = useState(0);

    useEffect(() => {
        fetchCourses().then(res => {
            setCourses(res.data);
        });
    },[]);

    const chooseToSelect = (slug) => {
        
        fetchSingleCourseWithModule(slug).then(res => {
            setSingleCourse(res.data);
            // console.log(res.data);
        });
        toggleAddModal();
    };
    const toggleAddModal = () => {
        setFinalPrice(0);
        setIsOpen(!isOpen);
    }
    
    //card element click action
    const btnAction = (cardID,price) => {
        var element = document.getElementById(cardID);
        console.log(element);
        if(element.classList.contains("card1")) {
            setFinalPrice(finalPrice+price);
            element.classList.remove("card1");
            element.classList.add("cardActive1");
        } else {
            setFinalPrice(finalPrice-price);
            element.classList.remove("cardActive1");
            element.classList.add("card1");
        }
    };

    const payForCourse = () => {
        if(singleCourse.noSingleModule) {
            let allModules = [];
            for(let j=0,n=singleCourse.allModule.length;j<n;j++) {
                
                let qstns=[];
                // let sttttrd = [];
                let qCount1=0;
                while(qstns.length < 10) {
                    let qqq = {  
                        question : singleCourse.allModule[j].moduleExam[qCount1].question,
                        option1 : singleCourse.allModule[j].moduleExam[qCount1].option1,
                        option2 : singleCourse.allModule[j].moduleExam[qCount1].option2,
                        option3 : singleCourse.allModule[j].moduleExam[qCount1].option3,
                        option4 : singleCourse.allModule[j].moduleExam[qCount1].option4,
                        oriAnswer : singleCourse.allModule[j].moduleExam[qCount1].answer,
                        priAnswer : "",
                        postAnswer : ""
                    }
                    qstns.push(qqq);  
                    qCount1++;    
                    // let flag1 = false;
                    // let random = Math.floor(Math.random() * (n + 1));
                    // if(sttttrd.length){for(let check1=0,n1=sttttrd.length;check1<n1;check1++){
                    //     if(sttttrd[check1]===random) { flag1 = true; }
                    // }}
                    // if((!qstns.length) || flag1) {
                    // if(qstns.indexOf(random) === -1) {
                        // sttttrd.push(random);
                    // }          
                }
                let modDtl = {
                    modID : singleCourse.allModule[j]._id,
                    moduleName : singleCourse.allModule[j].moduleName,
                    preQusetions:qstns,
                    preStatus: false,
                    postStatus: false,
                    finalStatus: false,
                    preNumber: 0,
                    postNumber: 0,
                    finalNumber: 0,
                    preTime: singleCourse.allModule[j].examTime.preTest,
                    postTime: singleCourse.allModule[j].examTime.postTest,
                    finalTime: singleCourse.allModule[j].examTime.exitTest
                }
                allModules.push(modDtl);
                // console.log(sttttrd);
            }
            const enrolledCourses = {
                courseName: singleCourse.couseName,
                courseSlug: singleCourse.couseSlug,
                fullCourseTaken: true,
                modules: allModules,
                paymentStatus: "Pending",
                paid: 0
            }
            console.log(enrolledCourses);
            registerNewCourse(studentStrg.studentEmail,enrolledCourses).then(res => {                
                const student = res.data;
                localStorage.setItem('student', JSON.stringify(student));
            });            
        } else {
            let doc = document.getElementById('top_row_card');
            let selectedModules = [];
            let list = doc.getElementsByClassName("cardActive1");
            for (let count = 0, n = list.length; count < n; count++) {
                let el = list[count];
                let selected_modID = el.id
                let selected_moduleName = el.getAttribute("data-modname");
                for (let modC = 0, nn = singleCourse.allModule.length; modC < nn; modC++){
                    if(singleCourse.allModule[modC]._id===selected_modID) {
                        let qstns=[];
                        // let sttttrd = [];
                        let qCount2=0;
                        while(qstns.length < 10) {
                            let qqq = {  
                                question : singleCourse.allModule[modC].moduleExam[qCount2].question,
                                option1 : singleCourse.allModule[modC].moduleExam[qCount2].option1,
                                option2 : singleCourse.allModule[modC].moduleExam[qCount2].option2,
                                option3 : singleCourse.allModule[modC].moduleExam[qCount2].option3,
                                option4 : singleCourse.allModule[modC].moduleExam[qCount2].option4,
                                oriAnswer : singleCourse.allModule[modC].moduleExam[qCount2].answer,
                                priAnswer : "",
                                postAnswer : ""
                            }
                            qstns.push(qqq);   
                            qCount2++;
                            // sttttrd.push(random);
                            // let flag2 = false;
                            // let random = Math.floor(Math.random() * (n + 1));
                            // if(sttttrd.length){for(let check2=0,n2=sttttrd.length;check2<n2;check2++){
                            //     if(sttttrd[check2]===random) { flag2 = true; }
                            // }}
                            // if((!qstns.length) || flag2) {
                            // if(qstns.indexOf(random) === -1) {
                            // }                
                        }
                        let modDtl = {
                            modID : selected_modID,
                            moduleName : selected_moduleName,
                            preQusetions:qstns,
                            preStatus: false,
                            postStatus: false,
                            finalStatus: false,
                            preNumber: 0,
                            postNumber: 0,
                            finalNumber: 0,
                            preTime: singleCourse.allModule[modC].examTime.preTest,
                            postTime: singleCourse.allModule[modC].examTime.postTest,
                            finalTime: singleCourse.allModule[modC].examTime.exitTest,
                        }
                        selectedModules.push(modDtl);
                    }
                }
            }

            const enrolledCourses1 = {
                courseName: singleCourse.couseName,
                courseSlug: singleCourse.couseSlug,
                fullCourseTaken: false,
                modules: selectedModules,
                paymentStatus: "Pending",
                paid: 0
            }
            console.log(enrolledCourses1);
            registerNewCourse(studentStrg.studentEmail,enrolledCourses1).then(res => {                
                const student = res.data;
                localStorage.setItem('student', JSON.stringify(student));
            });     
        }
            
        toggleAddModal();


    };
    const mystyle = {
        padding: "10px",
        borderRadius: "25px",
        boxShadow: "0 20px 20px #737dc1",
        backgroundColor: "#f0f3fe",
        borderTop: "3px solid #fff",
        borderLeft: "3px solid #e6e6e6",
        borderBottom: "7px solid #ced2f4"
    };
    
    return(
        <>
            <Header />
            <Container className="container shadow px-4 py-2" style={{borderRadius:'20px', backgroundColor:'#767eb69e'}}>
                <Row className="row py-4" style={{borderRadius:'20px', backgroundColor:'#fbfbfbe3', boxShadow:'0px 0px -20px #666'}}>                    
                    {courses?.map(crs =>
                        <Card key={crs._id} className="p-2 m-2 col-lg-3 col-md-3 col-sm-6 col-xs-12" style={mystyle}>
                            
                            <Card.Body className="text-center">
                                <div><b>Name : </b>{crs.couseName}</div>
                                <div><b>Modules : </b>{crs.courseModule.length}</div>
                                <div><b>Tenure : </b>{crs.couseTenure} Month</div>
                            </Card.Body>
                            <Card.Footer className="d-flex justify-content-center">
                            <Button className="btn btn-sm btn-info w-100" onClick={()=>{ chooseToSelect(crs.couseSlug)}}>
                                        Choose To Buy
                                    </Button>
                            </Card.Footer>
                        </Card> 
                    )}
                </Row>

                <Modal show={isOpen} onHide={toggleAddModal} style={{zIndex:'9999'}} >
                    <Modal.Header>
                        <Modal.Title>Enroll to Course Module</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>                        
                        {singleCourse.noSingleModule?  (
                            <Form>
                                <div className='container'>Have to purchase the full course. You are not allowed to choose single module.</div>
                                {singleCourse.allModule.length ? (
                                    <>{singleCourse.allModule.map((mdle,i) =>
                                        <Card key={i} id={mdle._id} className='cardActive1'>                                    
                                            <Card.Body>
                                                <div>{mdle.moduleName}</div>
                                            </Card.Body>
                                        </Card>                      
                                    )}</>
                                ) : (
                                    <div>No module Found, choose another course.</div>
                                )}

                                <Form.Group className="mb-3 d-flex justify-content-between" controlId="formButtons">
                                    <Button className="btn btn-danger btn-sm" onClick={toggleAddModal}>Cancel</Button>
                                    {/* <Button className="btn btn-info btn-sm" href="/enrolledCourses" type="button" onClick={() => payForCourse(singleCourse.couseSlug)}>Pay ₹ {singleCourse.coursePrice.selfPrice}</Button> */}
                                    <a className="btn btn-info btn-sm" href="/enrolledCourses" onClick={() => payForCourse(singleCourse.couseSlug)}>Pay ₹ {singleCourse.coursePrice.selfPrice}</a>
                                </Form.Group>
                            </Form>
                        ) : (
                            <Form>
                                {singleCourse.allModule.length ? (                               
                                    <div id="top_row_card">
                                        {singleCourse.allModule.map((mdle,i) =>
                                            <Card key={i} id={mdle._id} data-modname={mdle.moduleName} className='card1' onClick={() => btnAction(mdle._id,mdle.modulePrice.selfPrice)}>                                    
                                                <Card.Body>
                                                    <div>{mdle.moduleName}</div>
                                                </Card.Body>
                                            </Card>                      
                                        )}
                                    </div>
                                ) : (
                                    <div>No module Found, choose another course.</div>
                                )}

                                <Form.Group className="mb-3 d-flex justify-content-between" controlId="formButtons">
                                    <Button className="btn btn-danger btn-sm" onClick={toggleAddModal}>Cancel</Button>
                                    <Button className="btn btn-info btn-sm" type="button" onClick={() => payForCourse(singleCourse.couseSlug)}>Pay ₹ {finalPrice}</Button>
                                </Form.Group>
                            </Form>
                        ) }
                    </Modal.Body>
                </Modal>
            </Container>    
        </>
    )
}
export default RegisterCourse;