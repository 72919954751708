import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../../Header/Header';
import { getProfile } from "../../../crud/common.crud";
import { Container,Row,Alert,Button  } from 'react-bootstrap-v5';

const PermissionDenied = () => {
    const studentStrg = JSON.parse(localStorage.getItem("student"));
    const [ permitted, setPermitted ] = useState(false);
    useEffect(() => {
        getProfile(studentStrg.studentEmail).then(res => {
            setPermitted(res.data.studentPermission);
        })
    }, [studentStrg.studentEmail]);
    const reeeld = () => {
        getProfile(studentStrg.studentEmail).then(res => {
            setPermitted(res.data.studentPermission);
        });
    };
    if(permitted) {
        return (<Navigate to="/dashboard" />);
    }
    return (
        <>
            <Header />
            <Container className="bg-light py-3">
                <Row>
                    {/* <Col md={8} className="my-auto"> */}
                        <Alert variant="danger">
                            Please check your registered email to get access to the system.<br />
                            If you did not receive the email, contact admin.
                        </Alert><br />
                    {/* </Col> */}
                        <Button onClick={()=>{ reeeld()}}>
                            Reload Page
                        </Button>
                </Row>
            </Container>
        </>        
    )
}
export default PermissionDenied;