import React, { useState, useEffect } from 'react';
import { Nav, Row } from "react-bootstrap-v5";

const Header = (props) => {
    const [loggedIn, setLoggedIn] = useState(false);
    return(
        <>
        
        { 
            loggedIn ? (
                <Nav className="navbar navbar-dark bg-Secondary justify-content-between text-dark">
                    <Nav.Item>
                        <Nav.Link href="/dashboard" className="text-dark">Dashboard</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/courses" className="text-dark">Courses</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/profile" className="text-dark">Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/" className="text-dark">Logout</Nav.Link>
                    </Nav.Item>
                </Nav>
            ): (
                
                <Nav className="navbar navbar-dark bg-light justify-content-md-center" style={{borderTop:'10px solid'}}>
                    {/* <Nav.Item>
                        <Nav.Link href="/" className="text-dark">dfh</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/activity" className="text-dark">gfhh</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/IDECourse" className="text-dark">htrturse</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/contact" className="text-dark">Contact</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/login" className="text-dark">Login</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/register" className="text-dark">Register</Nav.Link>
                    </Nav.Item> */}
                    Copyright © {new Date().getFullYear()} Kolkata Hormone Foundation | Powered by Grandred Technology
                </Nav>
            )
        }
        </>
        
    )
}
export default Header;