import React, { useState, useEffect } from 'react';
import Header from '../../Header/Header';
import { fetchSnglEnrlCrs} from "../../../crud/common.crud";
import { Card,Container,Row,Accordion,Col,ListGroup } from 'react-bootstrap-v5';

const EnrolledCourseDtl =() => {

    const studentDtl = JSON.parse(localStorage.getItem("student"));
    const slug = JSON.parse(localStorage.getItem("enrolledCourseDtlSlug"));
    const [selectedEnrolled, setSelectedEnrolled] = useState({
        courseName: '',
        courseSlug: '',
        fullCourseTaken: false,
        modules:[{
            _id : '',
            moduleName : '',
            preQusetions:[{  
                question : '',
                option1 : '',
                option2 : '',
                option3 : '',
                option4 : '',
                oriAnswer : '',
                priAnswer : '',
                postAnswer : '',
            }],
            preStatus: false,
            postStatus: false,
            finalStatus: false,
            preNumber: '',
            postNumber: '',
            finalNumber: '',
        }],
        paymentStatus: '',
        paid: 0
    });
    
    useEffect(() => {    
        fetchSnglEnrlCrs(slug,studentDtl.studentEmail).then(res => {
            setSelectedEnrolled(res.data);
            // setModuleCount(res.data.courseModule.length)
            console.log(res.data);
        }).catch((err)=>{
            console.log(err);
        });
    }, [slug]);

    const startExam = (couseSlug,_id) => {
        localStorage.setItem('exam_couseSlug', JSON.stringify(couseSlug));
        localStorage.setItem('exam_modID', JSON.stringify(_id));
    }
    

    return(
        <>
            <Header /><br/>
            
            <Container>                
                <Row>
                    { selectedEnrolled.modules.length ? (
                        <Accordion>
                            {selectedEnrolled.modules.map((mdle,i) =>
                            <Card key={i}>
                                <Accordion.Toggle as={Card.Header} eventKey={(i+1)}>
                                    <Row>
                                        <Col md>{mdle.moduleName}</Col>
                                        <Col md className="text-center">{mdle.moduleTeacher}</Col>
                                        <Col md className="text-end">&nbsp;</Col>
                                    </Row>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={(i+1)}>
                                    <Row>
                                        <Col md={3}>
                                            <Card>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item>Pre-test</ListGroup.Item>
                                                    <ListGroup.Item>Contents</ListGroup.Item>
                                                    <ListGroup.Item>Post-test</ListGroup.Item>
                                                    <ListGroup.Item>Exit-test</ListGroup.Item>
                                                </ListGroup>
                                            </Card>
                                        </Col>
                                        <Col md={9}>
                                            <Card>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item>
                                                        <Row>
                                                            <Col>
                                                                Status:
                                                                {(selectedEnrolled.modules.preStatus)? 
                                                                    ( <>Completed</>):( <>Not Complete</> )
                                                                }
                                                            </Col>
                                                            <Col>
                                                                {(selectedEnrolled.modules.preStatus)? 
                                                                    ( 
                                                                        <a href="#" className="btn btn-sm btn-success w-100">Done</a>
                                                                    ):( 
                                                                        <a href="/preExamStart" className="btn btn-sm btn-info w-100" 
                                                                            onClick={()=>{ startExam(selectedEnrolled.courseSlug,mdle._id)}}>
                                                                            Start
                                                                        </a>
                                                                    )
                                                                }                                                            
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        {(selectedEnrolled.modules.preStatus)? (
                                                            <Row>
                                                            <Col md={3}><a href='/ContentRead' className='text-decoration-none'>
                                                                <Card className="border border-primary shadow">
                                                                <Card.Body>Topic name or file Name</Card.Body>
                                                                </Card></a>
                                                            </Col>
                                                            </Row>
                                                        ):(
                                                            <Row>
                                                                <Col className='text-center'>
                                                                    Have to complete the Pretest to view content
                                                                    <Row>
                                                                    <Col md={3}><a href='/ContentRead' className='text-decoration-none'>
                                                                        <Card className="border border-primary shadow">
                                                                        <Card.Body>Topic name or file Name</Card.Body>
                                                                        </Card></a>
                                                                    </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        )}                                                        
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        {(selectedEnrolled.modules.preStatus)? (
                                                            <Row>
                                                                <Col>
                                                                    Status:
                                                                    {(selectedEnrolled.modules.postStatus)? 
                                                                        ( <>Not Complete</>):( <>Completed</> )
                                                                    }
                                                                </Col>
                                                                <Col>
                                                                    {(selectedEnrolled.modules.postStatus)? 
                                                                        ( 
                                                                            <a href="#" className="btn btn-sm btn-success w-100">Done</a>
                                                                        ):( 
                                                                            <a href="/postExamStart" className="btn btn-sm btn-primary w-100" 
                                                                                onClick={()=>{ startExam(selectedEnrolled.courseSlug,mdle._id)}}>
                                                                                Start
                                                                            </a>
                                                                        )
                                                                    }                                                            
                                                                </Col>
                                                            </Row>
                                                        ):(
                                                            <Row>
                                                                <Col className='text-center'>
                                                                    Have to complete the Pretest to appear in PostTest
                                                                </Col>
                                                            </Row>
                                                        )} 
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        {(selectedEnrolled.modules.preStatus)? (
                                                            <>
                                                                {(selectedEnrolled.modules.postStatus)? (
                                                                    <Row>
                                                                        <Col>
                                                                            Status:
                                                                            {(selectedEnrolled.modules.finalStatus)? 
                                                                                ( <>Not Complete</>):( <>Completed</> )
                                                                            }
                                                                        </Col>
                                                                        <Col>
                                                                            {(selectedEnrolled.modules.finalStatus)? 
                                                                                ( 
                                                                                    <a href="#" className="btn btn-sm btn-success w-100">Done</a>
                                                                                ):( 
                                                                                    <a href="/finalExamStart" className="btn btn-sm btn-warning w-100" 
                                                                                        onClick={()=>{ startExam(selectedEnrolled.courseSlug,mdle._id)}}>
                                                                                        Start
                                                                                    </a>
                                                                                )
                                                                            }                                                            
                                                                        </Col>
                                                                    </Row>
                                                                ):(
                                                                    <Row>
                                                                        <Col className='text-center'>
                                                                            Have to complete the PostTest to appear in ExitTest
                                                                        </Col>
                                                                    </Row>
                                                                )} 
                                                            </>
                                                        ):(
                                                            <Row>
                                                                <Col className='text-center'>
                                                                    Complete PreTest and PostTest to appear in ExitTest
                                                                </Col>
                                                            </Row>
                                                        )} 
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Accordion.Collapse>
                            </Card>                              
                            )}                 
                        </Accordion>
                    ) : (
                        <div>No module Found</div>
                    )}
                </Row>                
            </Container>   
        </>
    )
}
export default EnrolledCourseDtl;