import axios from './axios.interceptor';
import baseUrl from './api.util';

export const COURSES = `${baseUrl}/course`;
export const STUDENTS = `${baseUrl}/student`;
export const LOGINSTUDENT = `${baseUrl}/auth/std/studentLogin`;
export const LOGOUTSTUDENT = `${baseUrl}/auth/std/studentLogout`;
export const MODULES = `${baseUrl}/crsmodule`;


export function loginStudent( studentEmail,studentPassword ) {
    return axios.post(LOGINSTUDENT, { studentEmail,studentPassword });
}
export function logoutStudent( studentEmail ) {
    return axios.post( LOGOUTSTUDENT, { studentEmail });
}
export function createStudent(
    studentName,
    // studentAddress1,
    // studentCity,
    // studentPin,
    studentEmail,
    studentPassword,
    studentPhone1,
    // studentMedRegNo 
    ) {
    return axios.post(STUDENTS, { 
        studentName:studentName,
        // studentAddress:{
        //     address: studentAddress1,
        //     city:studentCity,
        //     pin:studentPin
        // },
        studentEmail:studentEmail,
        studentPassword:studentPassword,
        source: "Self",
        studentPhone: {
            mobile:studentPhone1,
            whatsapp:studentPhone1
        },
        // studentProfDtls: {
        //     medRegNo:studentMedRegNo
        // } 
    });
}
export function updateStudent( slugEmail,stdDetails ) {
    const stdURL = `${STUDENTS}/${slugEmail}`;
    return axios.patch(stdURL, { stdDetails });
}
export function registerNewCourse(studentEmail,enrolledCourses) {
    const sCors = `${STUDENTS}/addCourse/${studentEmail}`;
    return axios.post(sCors,{enrolledCourses:enrolledCourses});
}

export function getProfile(studentEmail) {
    const stdDtl = `${STUDENTS}/${studentEmail}`;
    return axios.get(stdDtl);
}

export function fetchCourses() {
    return axios.get(COURSES);
}
export function fetchSingleCourse(slug) {
    const sCors = `${COURSES}/${slug}`;
    return axios.get(sCors);
}
export function fetchSingleCourseWithModule(slug) {
    const sCors = `${STUDENTS}/crsWithMod/${slug}`;
    return axios.get(sCors);
}
export function fetchSnglEnrlCrs(slug,studentEmail) {
    const sCors = `${STUDENTS}/enrolled/single/${studentEmail}/${slug}`;
    return axios.get(sCors);
}
export function getCourseModules( slug ) {
    const moduleURL = `${MODULES}/${slug}`;
    return axios.get(moduleURL);
}

export function updateExamStatusP(studentEmail,exam_couseSlug,exam_modID) {    
    const sCors = `${STUDENTS}/examStatP/${studentEmail}/${exam_couseSlug}/${exam_modID}`;
    return axios.patch(sCors);
}
export function preExamSubmit(priiiiAns,exam_couseSlug,exam_modID,score,studentEmail) {
    let asd=[];
    for(let i=0;i<10;i++) {
        let qwe = { priAnswer:priiiiAns[i] }
        asd.push(qwe);
    }
    const moddd = {
        preQusetions:asd,
        preStatus: true,
        preNumber: score
    }
    const sCors = `${STUDENTS}/examSubmit/${studentEmail}/${exam_couseSlug}/${exam_modID}`;
    return axios.patch(sCors,{moddd});
}
// export function postExamSubmit() {
//     return true;
// }
// export function postExamSubmit(ans1, ans2, ans3, ans4, ans5, ans6, ans7, ans8, ans9, ans10,exam_couseSlug,exam_modID,studentEmail) {
//     return ans1;
// }
