import React, { useState} from 'react';
import { Navigate } from 'react-router-dom';
import { loginStudent } from "../../../crud/common.crud";
import loginImage from '../../images/signin.png';
import { Card,Container,Row,Form,Button, Col } from 'react-bootstrap-v5';
import '../../css/style.css';

const Login = () => {
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword ] = useState('');
    const [ redirect, setRedirect ]= useState(false);
    const [ clicked, setClicked ] = useState(0);
    const [ permitted, setPermitted ] = useState(false);

    const handleSubmit=(e) => {
        e.preventDefault();  
        setClicked(1);   
        loginStudent(emailAddress,password).then((res)=>{
            const student = res.data;
            localStorage.setItem('student', JSON.stringify(student));
            localStorage.setItem('loggedin', JSON.stringify(true));
            setPermitted(student.studentPermission);
            setRedirect(true);
        }).catch((err)=>{
            console.log(err);
        });
    };
    
    if(redirect) {
        if(permitted) {
            return (<Navigate to="/dashboard" />);
        } else {
            return (<Navigate to="/permissionDenied" />);
        }
        
    }
    return(
        <>
            <Container className="bg-light py-3 right-animate" 
                style={{
                    borderRadius:'35px', 
                    backgroundImage:`url(${loginImage}), linear-gradient(#0ccda3, #c1fcd3)`, 
                    borderBottom:'10px solid #311f69c7',
                    marginTop:'150px', 
                    // background: "linear-gradient(#000, #fff);",
                    boxShadow:'2px 2px 20px #666'
                }}>
                <Row className="mx-auto left-animate">
                <Col sm={4}>
                    <Card className="bg-transparent" style={{borderRadius:'15px', boxShadow:'2px 2px 20px #666'}}>
                        <Card.Header className='fw-bold text-center'>{clicked ? 'Loading...' : 'Welcome Back'}</Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formCourse">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control type="password" className="rounded-pill" 
                                        value={emailAddress}
                                        onChange={(e)=>{setEmailAddress(e.target.value)}} />  
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formTenure">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control className="rounded-pill" type="password" 
                                        value={password}
                                        onChange={(e)=>{setPassword(e.target.value)}} />  
                                </Form.Group>
                    
                                <Form.Group>
                                    <Button type="submit" className="btn btn-success btn-md rounded-pill">Login</Button>
                                    <a style={{float:'right'}} href="/forgeotPswd">Forgot Password</a>
                                    {/* <Button className="btn btn-success btn-sm" type="submit">Login</Button> */}
                                </Form.Group>
                            </Form>
                        </Card.Body>
                        <Card.Footer className='fw-bold text-center'>Don`t have Account? <a href="/register"> Register</a></Card.Footer>
                    </Card>
                </Col>
                    {/* <Col sm={8}>
                        <img className="img-fluid" src={sbi}/>
                    </Col> */}
                </Row>
            </Container>    
        </>
    )
}
export default Login;