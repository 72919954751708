import React, { useState, useEffect } from 'react';
import Header from '../../Header/Header';
import { getProfile } from "../../../crud/common.crud";
import { Card, Container, Row } from 'react-bootstrap-v5';

const EnrolledCourses = (props) => {

    const studentStrg = JSON.parse(localStorage.getItem("student"));
    // const [isOpen, setIsOpen] = useState(false);
    const [enrolledCourses, setEnrolledCourses] = useState([]);

    useEffect(() => {
        getProfile(studentStrg.studentEmail).then(res => {
            const student = res.data;
            setEnrolledCourses(student.enrolledCourses);
            localStorage.setItem('student', JSON.stringify(student));
        })
    }, [studentStrg.studentEmail]);

    const chooseToSelect = (slug) => {
        for (let count = 0, n = enrolledCourses.length; count < n; count++) {
            if(slug===enrolledCourses[count].courseSlug) {
                localStorage.setItem('enrolledCourseDtlSlug', JSON.stringify(slug));
            }
        }
    };

    const mystyle = {
        padding: "10px",
        borderRadius: "25px",
        boxShadow: "0 20px 20px #737dc1",
        backgroundColor: "#f0f3fe",
        borderTop: "3px solid #fff",
        borderLeft: "3px solid #e6e6e6",
        borderBottom: "7px solid #ced2f4"
    };
    
    return(
        <>
        <Header />
            <Container className="container shadow px-4 py-2" style={{borderRadius:'20px', backgroundColor:'#767eb69e'}}>
                <Row className="row py-4" style={{borderRadius:'20px', backgroundColor:'#fbfbfbe3', boxShadow:'0px 0px -20px #666'}}>          
                {enrolledCourses?.map(crs =>
                    <Card key={crs.courseSlug} className="p-2 m-2 col-lg-3 col-md-3 col-sm-6 col-xs-12" style={mystyle}>
                        <Card.Body className="text-center">
                            <div><b>Name : </b>{crs.courseName}</div>
                        </Card.Body>
                        <Card.Footer className="d-flex justify-content-center">
                            <a href="/enrolledCourseDtl" className="btn btn-sm btn-warning w-100" 
                                onClick={(e)=>{ chooseToSelect(crs.courseSlug)}}>
                                Check Modules
                            </a>
                        </Card.Footer>
                    </Card> 
                )}
                </Row>
                
            </Container>
        </>
    )
}
export default EnrolledCourses;