import React, { useState, useEffect } from 'react';
import Header from '../../Header/Header';
import PDFViewer from 'pdf-viewer-reactjs';
import pdf from '../../contents/pdf.pdf';
import { fetchSingleCourse,getProfile,fetchSnglEnrlCrs} from "../../../crud/common.crud";
import { Card,Container,Row,Accordion,Button,Col,ListGroup,Badge } from 'react-bootstrap-v5';

const ExamplePDFViewer = () => {
    return (
        <PDFViewer
            document={{
                url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf',
            }}
        />
    )
}

export default ExamplePDFViewer;