import React from 'react';
// import ReactDOM from "react-dom";
import Header from '../../Header/Header';
import { Card, Container, Row, Button,Col } from 'react-bootstrap-v5';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import './timer.css';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';
import Step9 from './Step9';
import Step10 from './Step10';

const examgrid = {
  padding: "10px",
  borderRadius: "25px",
  boxShadow: "0 20px 20px #737dc1",
  backgroundColor: "#f0f3fe",
  borderTop: "3px solid #fff",
  borderLeft: "3px solid #e6e6e6",
  borderBottom: "7px solid #ced2f4"
};
const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return <div className="timer fs-4 text-center">Too lale...</div>;
  }

  return (
    <div className="timer fs-4 text-center">
      <div className="text">Remaining</div>
      <div className="value">{(new Date(remainingTime * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0]}</div>
      {/* <div className="value">{remainingTime}</div> */}
      {/* <div className="text">seconds</div> */}
    </div>
  );
};

const questionArray = [
  {
    question:"this is first question",
    option1:"a1",
    option2:"b1",
    option3:"c1",
    option4:"d1"
  },
  {
    question:"this is second question",
    option1:"a2",
    option2:"b2",
    option3:"c2",
    option4:"d2"
  },
  {
    question:"this is third question",
    option1:"a3",
    option2:"b3",
    option3:"c3",
    option4:"d3"
  },
  {
    question:"this is forth question",
    option1:"a3",
    option2:"b3",
    option3:"c3",
    option4:"d3"
  },
  {
    question:"this is fifth question",
    option1:"a3",
    option2:"b3",
    option3:"c3",
    option4:"d3"
  },
  {
    question:"this is sixth question",
    option1:"a3",
    option2:"b3",
    option3:"c3",
    option4:"d3"
  },
  {
    question:"this is seventh question",
    option1:"a3",
    option2:"b3",
    option3:"c3",
    option4:"d3"
  },
  {
    question:"this is eighth question",
    option1:"a3",
    option2:"b3",
    option3:"c3",
    option4:"d3"
  },
  {
    question:"this is ninth question",
    option1:"a3",
    option2:"b3",
    option3:"c3",
    option4:"d3"
  },
  {
    question:"this is tenth question",
    option1:"a3",
    option2:"b3",
    option3:"c3",
    option4:"d3"
  }
];
export default class FinalExamstart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 1,
      ans1: '',
      ans2: '',
      ans3: '',
      ans4: '',
      ans5: '',
      ans6: '',
      ans7: '',
      ans8: '',
      ans9: '',
      ans10: '',
    }
  }

  handleChange = event => {
    const {name, value} = event.target;
    this.setState({
        [name]: value
    });
    console.log(name, value);
  }
    
  handleSubmit = event => {
      event.preventDefault();
      const { ans1, ans2, ans3, ans4, ans5, ans6, ans7, ans8, ans9, ans10 } = this.state;
      console.log(ans1, ans2, ans3, ans4, ans5, ans6, ans7, ans8, ans9, ans10);
  }
    
  _next = () => {
    let currentStep = this.state.currentStep
    currentStep = currentStep >= 9? 10 : currentStep + 1
    this.setState({
      currentStep: currentStep
    })
  }
    
  _prev = () => {
    let currentStep = this.state.currentStep
    currentStep = currentStep <= 1? 1: currentStep - 1
    this.setState({
      currentStep: currentStep
    })
  }
  
  previousButton() {
    let currentStep = this.state.currentStep;
    if(currentStep !==1){
      return (
        <Button 
          className="btn btn-sm btn-secondary" 
          type="button" onClick={this._prev}>
          Previous
        </Button>
      )
    }
    return null;
  }
  
  nextButton(){
    let currentStep = this.state.currentStep;
    if(currentStep <10){
      return (
        <Button 
          className="btn btn-sm btn-primary float-right" 
          type="button" onClick={this._next}>
          Next
        </Button>        
      )
    }
    return null;
  }
   

  render() {    
    return (
      <>
        <Header />
        <Container><br/>
            
              <Col className="timer col">
                <div className=" bg-light" style={{width:'180px', borderRadius:'50%'}}>
                    <CountdownCircleTimer
                      isPlaying
                      duration={1800}
                      colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                      colorsTime={[10, 6, 3, 0]}
                      onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                    >
                      {renderTime}
                    </CountdownCircleTimer></div>
                  </Col>
            
          <Card className="text-center" style={examgrid}>
          <Card.Header style={examgrid}>Course Name : Module Name</Card.Header>
          <Card.Body className="bg-white" style={examgrid}>
            <Card.Title><p>Question {this.state.currentStep} </p> </Card.Title>
            <React.Fragment>
              <form onSubmit={this.handleSubmit}>
                <Step1 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans1={this.state.ans1}
                  questionss={questionArray}
                />
                <Step2 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans2={this.state.ans2}
                  questionss={questionArray}
                />
                <Step3 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans3={this.state.ans3}
                  questionss={questionArray}
                />
                <Step4 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans4={this.state.ans4}
                  questionss={questionArray}
                />
                <Step5 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans5={this.state.ans5}
                  questionss={questionArray}
                />
                <Step6 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans6={this.state.ans6}
                  questionss={questionArray}
                />
                <Step7 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans7={this.state.ans7}
                  questionss={questionArray}
                />
                <Step8 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans8={this.state.ans8}
                  questionss={questionArray}
                />
                <Step9
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans9={this.state.ans9}
                  questionss={questionArray}
                />
                <Step10
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans10={this.state.ans10}
                  questionss={questionArray}
                />
                {this.previousButton()}
                {this.nextButton()}
        
              </form>
            </React.Fragment>
          </Card.Body>
          </Card>
        </Container>
      </>
    );
  }
}