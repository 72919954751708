import React from 'react';
// import ReactDOM from "react-dom";
import Header from '../../Header/Header';
import { Card, Container, Button,Col } from 'react-bootstrap-v5';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import './timer.css';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';
import Step9 from './Step9';
import Step10 from './Step10';
import { updateExamStatusP } from '../../../crud/common.crud';

const questionArray = [];
let examTime,halfTime,qtrTime,courseName,modName;
const exam_couseSlug = JSON.parse(localStorage.getItem("exam_couseSlug"));
const exam_modID = JSON.parse(localStorage.getItem("exam_modID"));
const studentStrg = JSON.parse(localStorage.getItem("student"));
if(studentStrg) {
  for(let csIndex=0,n=studentStrg.enrolledCourses.length;csIndex<n;csIndex++) {
    if(studentStrg.enrolledCourses[csIndex].courseSlug===exam_couseSlug) {
      courseName=studentStrg.enrolledCourses[csIndex].courseName;
      const crsModulessss=studentStrg.enrolledCourses[csIndex].modules;
      for(let modIndex=0,n=crsModulessss.length;modIndex<n;modIndex++) {
        if(crsModulessss[modIndex]._id===exam_modID){
          modName=crsModulessss[modIndex].moduleName;
          const targetMod = crsModulessss[modIndex];
          for(let qc=0;qc<10;qc++){
            let qqssttnn = {
              question : targetMod.preQusetions[qc].question,
              option1 : targetMod.preQusetions[qc].option1,
              option2 : targetMod.preQusetions[qc].option2,
              option3 : targetMod.preQusetions[qc].option3,
              option4 : targetMod.preQusetions[qc].option4,
              oriAnswer : targetMod.preQusetions[qc].oriAnswer
            }
            questionArray.push(qqssttnn);
          }
          // examTime=10;
          examTime=targetMod.preTime*60;
          halfTime=Math.floor(examTime/2)*60;
          qtrTime=Math.floor(examTime/4)*60;
          console.log(halfTime,qtrTime);
        }
      }
    }
  }
}
const examgrid = {
  padding: "10px",
  borderRadius: "25px",
  boxShadow: "0 20px 20px #737dc1",
  backgroundColor: "#f0f3fe",
  borderTop: "3px solid #fff",
  borderLeft: "3px solid #e6e6e6",
  borderBottom: "7px solid #ced2f4"
};
const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return <div className="timer fs-4 text-center">Too lale...</div>;
  }

  return (
    <div className="timer fs-4 text-center">
      <div className="text">Remaining</div>
      <div className="value">{(new Date(remainingTime * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0]}</div>
    </div>
  );
};

export default class PreExamstart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 1,
      ans1: '',
      ans2: '',
      ans3: '',
      ans4: '',
      ans5: '',
      ans6: '',
      ans7: '',
      ans8: '',
      ans9: '',
      ans10: '',
    }
  }

  componentDidMount() {
    updateExamStatusP(studentStrg.studentEmail,exam_couseSlug,exam_modID).then(res => {
      const courses = res.data;  
      this.setState({ courses });
    });
  }

  handleChange = event => {
    const {name, value} = event.target;
    this.setState({
      [name]: value
    });
    // console.log("name===>"+event.target.name,"value===>"+event.target.value);
  }
    
  handleSubmit = event => {

    event.preventDefault();
    const { ans1, ans2, ans3, ans4, ans5, ans6, ans7, ans8, ans9, ans10 } = this.state;
    let ans = [];
    let score=0;
    ans.push(ans1);
    ans.push(ans2);
    ans.push(ans3);
    ans.push(ans4);
    ans.push(ans5);
    ans.push(ans6);
    ans.push(ans7);
    ans.push(ans8);
    ans.push(ans9);
    ans.push(ans10);
    
    for(let ccc=0;ccc<10;ccc++) {
      if(questionArray[ccc].oriAnswer===ans[ccc]) { score++; }
    }
    // preExamSubmit(ans,exam_couseSlug,exam_modID,score,studentStrg.studentEmail).then(res => {                
    //   const student = res.data;
    //   localStorage.setItem('student', JSON.stringify(student));
    // });     
    console.log(questionArray,ans,score);
    // console.log(ans);
  }
    
  _next = () => {
    let currentStep = this.state.currentStep
    currentStep = currentStep >= 9? 10 : currentStep + 1
    this.setState({
      currentStep: currentStep
    })
  }
    
  _prev = () => {
    let currentStep = this.state.currentStep
    currentStep = currentStep <= 1? 1: currentStep - 1
    this.setState({
      currentStep: currentStep
    })
  }
  
  previousButton() {
    let currentStep = this.state.currentStep;
    if(currentStep !==1){
      return (
        <Button 
          className="btn btn-sm btn-secondary" 
          type="button" onClick={this._prev}>
          Previous
        </Button>
      )
    }
    return null;
  }
  
  nextButton(){
    let currentStep = this.state.currentStep;
    if(currentStep <10){
      return (
        <Button 
          className="btn btn-sm btn-primary float-right" 
          type="button" onClick={this._next}>
          Next
        </Button>        
      )
    }
    return null;
  }
   

  render() {    
    return (
      <>
        <Header />
        <Container><br/>
            
          <Col className="timer col">
            <div className=" bg-light" style={{width:'180px', borderRadius:'50%'}}>
              <CountdownCircleTimer
                isPlaying
                duration={examTime}
                // colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                // colorsTime={[10, 6, 3, 0]}
                colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                colorsTime={[{examTime}, {halfTime}, {qtrTime},0]}
                // colorsTime={[{examTime}, {halfTime}, {qtrTime},0]}
                onComplete={() => ({ shouldRepeat: false, delay: 1 })}
              >
                {renderTime}
              </CountdownCircleTimer>
            </div>
          </Col>
            
          <Card className="text-center" style={examgrid}>
          <Card.Header style={examgrid}>{courseName} : {modName} : PreExam</Card.Header>
          <Card.Body className="bg-white" style={examgrid}>
            <Card.Title><p>Question {this.state.currentStep} </p> </Card.Title>
            <React.Fragment>
              <form onSubmit={this.handleSubmit}>
                <Step1 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans1={this.state.ans1}
                  questionss={questionArray}
                />
                <Step2 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans2={this.state.ans2}
                  questionss={questionArray}
                />
                <Step3 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans3={this.state.ans3}
                  questionss={questionArray}
                />
                <Step4 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans4={this.state.ans4}
                  questionss={questionArray}
                />
                <Step5 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans5={this.state.ans5}
                  questionss={questionArray}
                />
                <Step6 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans6={this.state.ans6}
                  questionss={questionArray}
                />
                <Step7 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans7={this.state.ans7}
                  questionss={questionArray}
                />
                <Step8 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans8={this.state.ans8}
                  questionss={questionArray}
                />
                <Step9
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans9={this.state.ans9}
                  questionss={questionArray}
                />
                <Step10
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  ans10={this.state.ans10}
                  questionss={questionArray}
                />
                {this.previousButton()}
                {this.nextButton()}
        
              </form>
            </React.Fragment>
          </Card.Body>
          </Card>
        </Container>
      </>
    );
  }
}