import React, { useState} from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../../Header/Header';
import { createStudent } from "../../../crud/common.crud";
import loginImage from '../../images/regtest.png';
import { Container, Row, Form, Card, Button, Col } from 'react-bootstrap-v5';
import { ToastContainer, toast } from 'react-toastify';
import '../../css/style.css';

const Register = () => {
    
    const [ redirect, setRedirect ]= useState(false);
    const [ permitted, setPermitted ] = useState(false);
    const [ clicked, setClicked] = useState(0);
    const [ studentName, setStudentName] = useState('');
    // const [ studentAddress, setStudentAddress ] = useState('');
    // const [ studentCity, setStudentCity ] = useState('');
    // const [ studentPin, setStudentPin ] = useState('');
    const [ pswd, setPswd ] = useState('');
    const [ studentEmail, setStudentEmail ] = useState('');
    const [ studentPassword, setStudentPassword ] = useState('');
    const [ studentPhone, setStudentPhone ] = useState('');
    // const [ studentMedRegNo, setStudentMedRegNo ] = useState('');

    const notify_fail = () => toast.error('Password Mismatch Use Same Password', {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });


    const handleSubmit=(e) => {
        e.preventDefault();  
        setClicked(1);    
        if(pswd===studentPassword)
        {
            createStudent(
                studentName,
                // studentAddress,
                // studentCity,
                // studentPin,
                studentEmail,
                studentPassword,
                studentPhone,
                // studentMedRegNo
            ).then((res)=>{
                const student = res.data;
                localStorage.setItem('student', JSON.stringify(student));
                localStorage.setItem('loggedin', JSON.stringify(true));
                setPermitted(student.studentPermission);
                setRedirect(true);
                // window.location.reload();
            }).catch((err)=>{
                console.log(err);
            });
        }  
        else{
            notify_fail();
            console.log("password mismatch")
        }
        
    };
    
    if(redirect) {
        if(permitted) {
            return (<Navigate to="/dashboard" />);
        } else {
            return (<Navigate to="/permissionDenied" />);
        }        
    }
    return(
        <>
             <Header />
             <ToastContainer />
            <Container  className="bg-light py-3 left-animate" 
                style={{borderRadius:'35px', backgroundImage:`url(${loginImage}), linear-gradient(#0ccda3, #c1fcd3)`,
                borderBottom:'10px solid #311f69c7',marginTop:'10px',marginBottom:'20px', 
                // background: "linear-gradient(#000, #fff)",
                boxShadow:'0px 0px 50px #311f69c7'}}>
             <Row className="mx-auto">
                <Col sm={5}>
                    <Card className="bg-transparent left-animate" style={{borderRadius:'15px', boxShadow:'2px 2px 20px #666'}}>
                        <Card.Header className='fw-bold text-center'>{clicked ? 'Loading...' : 'Registration'}</Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formName">
                                    <Form.Label>Student Name</Form.Label>
                                    <Form.Control type="text" 
                                        value={studentName}
                                        onChange={(e)=>{setStudentName(e.target.value)}}  
                                        placeholder="Name for the Student" />  
                                </Form.Group>
                                {/* <Form.Group className="mb-3" controlId="formAddress">
                                    <Form.Label>Student Address</Form.Label>
                                    <Form.Control type="text" 
                                        value={studentAddress}
                                        onChange={(e)=>{setStudentAddress(e.target.value)}}  
                                        placeholder="Address of the Student" />  
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <div className="col-6">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control type="text" 
                                                value={studentCity}
                                                onChange={(e)=>{setStudentCity(e.target.value)}}  
                                                placeholder="City" />  
                                        </div> 
                                        <div className="col-6">
                                            <Form.Label>Pin</Form.Label>
                                            <Form.Control type="text" 
                                                value={studentPin}
                                                onChange={(e)=>{setStudentPin(e.target.value)}}  
                                                placeholder="Pin" />  
                                        </div>
                                    </Row>
                                </Form.Group> */}
                                <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Label>Student Email</Form.Label>
                                    <Form.Control type="email" 
                                        value={studentEmail}
                                        onChange={(e)=>{setStudentEmail(e.target.value)}}  
                                        placeholder="Email Address" />  
                                </Form.Group>
                                                                     
                                        <Row>
                                        <div className="col-6">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" 
                                                value={pswd}
                                                onChange={(e)=>{setPswd(e.target.value)}}  
                                                placeholder="Enter Password" />  
                                        </div>
                                        <div className="col-6">
                                            <Form.Group className="mb-3" controlId="formPassword">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control type="password"
                                                value={studentPassword}
                                                onChange={(e)=>{setStudentPassword(e.target.value)}}  
                                                placeholder="Confirm Password" /> 
                                            </Form.Group>
                                        </div> 
                                    </Row>
                                
                                <Form.Group className="mb-3" controlId="formPhone">
                                    <Form.Label>Student Phone</Form.Label>
                                    <Form.Control type="text" 
                                        value={studentPhone}
                                        onChange={(e)=>{setStudentPhone(e.target.value)}}  
                                        placeholder="Phone Number" />  
                                </Form.Group>
                                {/* <Form.Group className="mb-3" controlId="formMedical">
                                    <Form.Label>Student Medical Registration No</Form.Label>
                                    <Form.Control type="text" 
                                        value={studentMedRegNo}
                                        onChange={(e)=>{setStudentMedRegNo(e.target.value)}}  
                                        placeholder="Medical Registration No" />  
                                </Form.Group> */}
                                
                                <Form.Group>
                                    <Button className="btn btn-success btn-sm" type="submit">Register</Button>
                                </Form.Group>
                                <small>Already have account? <a href="/login"> Login</a></small>

                            </Form>
                        </Card.Body>
                    </Card>
                 </Col>  
                    {/* <Col sm={8} style={{backgroundImage:`url(${loginImage})`}}>
                    </Col>  */}
                </Row>
            </Container>    
        </>
    )
}
export default Register;