import React, { useState, useEffect } from 'react';
import Header from '../../Header/Header';
import { fetchCourses } from "../../../crud/common.crud";
import { Card, Container, Row, Button, Modal } from 'react-bootstrap-v5';

const Contact = (props) => {
    return (
    <>
        <Header/>
        <Container>
            <Row><h1>This is Gallery Page</h1></Row>
        </Container>

    </>
    )
}
export default Contact;