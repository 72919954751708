import React, { useState, useEffect } from 'react';
import Header from '../../Header/Header';
import Footer from '../../Header/footer';
import { fetchCourses } from "../../../crud/common.crud";
import adim from '../../images/address.png';
import emim from '../../images/email.png';
import phim from '../../images/phone.png';
import dp from '../../images/common_dp.png';
import hbnr from '../../images/slider1.jpg';
import { Card, Container, Row, Badge, Carousel, Col } from 'react-bootstrap-v5';

const Home = (props) => {
    return (

        <>
        <Header/>
        <Container fluid>
            <Row>
            <Carousel className="top-animate" fade>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={hbnr}
                    alt="First slide"
                    />
                    {/* <Carousel.Caption>
                    <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={hbnr}
                    alt="Second slide"
                    />

                    {/* <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={hbnr}
                    alt="Third slide"
                    />

                    {/* <Carousel.Caption>
                    <h3>Third slide label</h3>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
            </Carousel>
            </Row><br/>
            <Row>
                <Col lg={8} md={8} className="right-animate">
                    <Card className="border border-info shadow">
                    <Card.Header className="bg-info"><h5>Who we are …</h5></Card.Header>
                    <Card.Body>
                        <Card.Title>Driven by a vision to alleviate human suffering from diabetes, its complications and other endocrinal disorders, Kolkata Hormone Foundation was established on 8th June, 2005.</Card.Title>
                        <Card.Text>
                        It is an independent nonprofit foundation under West Bengal society Registration Act. Education is the most powerful catalyst for mental, physical and social wellbeing. Kolkata Hormone Foundation conducts patient education programs to incorporate lifestyle modification practices which promote a genuine sense of integration & harmony among diabetic patient.
                        It is also the objective of the foundation, to encourage practising doctors to keep themselves updated on current developments in medical practice (specially in the field of Diabetology & Endocrinology), so as to maintain a high professional standard.
                        </Card.Text>
                    </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} md={4} className="left-animate">
                    <Card className="bg-white shadow-animate" border="primary">
                    <Card.Header><h5 className="text-center">Notice Board</h5></Card.Header>
                    <Card.Body>
                        <div className="border border-1 rounded px-4 py-2 shadow">
                            <Row className="py-2">
                                <Col className="text-muted">Date : 15-03-2022</Col>
                                <Col><Badge bg="success">New Batch</Badge></Col>
                            </Row>
                            <Card.Title className="fs-6"># IDEC 2022 </Card.Title>
                            <Card.Text>
                            (7th) (Session: March to September) will start from March 2022
                            </Card.Text>
                        </div><br/>
                        <div className="border border-1 rounded px-4 py-2 shadow">
                            <Row className="py-2">
                                <Col className="text-muted">Date : 15-03-2022</Col>
                                <Col><Badge bg="primary">New Course</Badge></Col>
                            </Row>
                        <Card.Title className="fs-6"># Next Diabetes Self Management Education (DSME)</Card.Title>
                        <Card.Text>
                        programme will be on Saturday For registration, please call: (033) 2414 8295 /2966 1221
                        </Card.Text>
                        </div>
                    </Card.Body>
                    </Card>
                </Col>
            </Row><br/>
            
                 <div className="bg-info reveal">
                    <h5 className="text-center text-white p-4">Testimonials</h5>
                </div><br/>
                 <Row>
                        <Col className="text-center reveal" md={3} >
                            <div className=" py-2 shadow border border-1" style={{borderRadius:'10px'}}>
                            <a className="justify-content-md-center text-decoration-none text-dark" href="#whycourse">
                            <img 
                            className="img-fluid rounded-circle shadow mb-2" 
                            style={{width:'30%', border:'2px solid #0defcc', padding:'5px'}} 
                            src={dp} 
                            alt="username"
                            /> 
                            <h6 className="text-primary">Dr. Supritam Banerjee</h6>
                            The world is gripped by pandemics of obesity, diabetes mellitus, 
                            metabolic syndrome, iodine deficiency and osteoporosis....
                            </a>
                            </div>
                        </Col>
                        <Col className="text-center reveal" md={3} >
                            <div className=" py-2 shadow border border-1" style={{borderRadius:'10px'}}>
                            <a className="justify-content-md-center text-decoration-none text-dark" href="#whycourse">
                            <img 
                            className="img-fluid rounded-circle shadow mb-2" 
                            style={{width:'30%', border:'2px solid #0defcc', padding:'5px'}} 
                            src={dp} 
                            alt="username"
                            /> 
                            <h6 className="text-primary">Dr. Supritam Banerjee</h6>
                            The world is gripped by pandemics of obesity, diabetes mellitus, 
                            metabolic syndrome, iodine deficiency and osteoporosis....
                            </a>
                            </div>
                        </Col>
                        <Col className="text-center reveal" md={3} >
                            <div className=" py-2 shadow border border-1" style={{borderRadius:'10px'}}>
                            <a className="justify-content-md-center text-decoration-none text-dark" href="#whycourse">
                            <img 
                            className="img-fluid rounded-circle shadow mb-2" 
                            style={{width:'30%', border:'2px solid #0defcc', padding:'5px'}} 
                            src={dp} 
                            alt="username"
                            /> 
                            <h6 className="text-primary">Dr. Supritam Banerjee</h6>
                            The world is gripped by pandemics of obesity, diabetes mellitus, 
                            metabolic syndrome, iodine deficiency and osteoporosis....
                            </a>
                            </div>
                        </Col>
                        <Col className="text-center reveal" md={3} >
                            <div className=" py-2 shadow border border-1" style={{borderRadius:'10px'}}>
                            <a className="justify-content-md-center text-decoration-none text-dark" href="#whycourse">
                            <img 
                            className="img-fluid rounded-circle shadow mb-2" 
                            style={{width:'30%', border:'2px solid #0defcc', padding:'5px'}} 
                            src={dp} 
                            alt="username"
                            /> 
                            <h6 className="text-primary">Dr. Supritam Banerjee</h6>
                            The world is gripped by pandemics of obesity, diabetes mellitus, 
                            metabolic syndrome, iodine deficiency and osteoporosis....
                            </a>
                            </div>
                        </Col>
                        
                </Row>   
                <br/>
            <Row id="contact">
                <Col className="text-center reveal" sm><a className="justify-content-md-center" ><img src={adim}/></a><h6>Address</h6>
                    Flat GB, Chhayaneer Apartment
                    26A, Gariahat Road (South), Dhakuria
                    Kolkata - 700029
                </Col>
                <Col className="text-center reveal" sm><a className="justify-content-md-center"><img src={phim}/></a><h6>Contact No.</h6>
                    (033) 2414 8295 / 2966 1221<br/> 
                    <b>Mrs. Kheya Sarkar (Course Co-ordinator)</b><br/> 
                    +91 98305 51903
                </Col>
                <Col className="text-center reveal" sm><a className="justify-content-md-center"><img src={emim}/></a><h6>Email</h6> 
                    kolkatahormonefoundation@gmail.com
                    idec.desk@gmail.com (for IDEC)
                </Col>
                <Col className="text-center reveal" sm><a className="justify-content-md-center"><img src={emim}/></a><h6>Email</h6> 
                    kolkatahormonefoundation@gmail.com
                    idec.desk@gmail.com (for IDEC)
                </Col>
                <iframe className="reveal" src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14742.90604877933!2d88.3671!3d22.514442!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcb81c9362f43547!2sKolkata%20Hormone%20Foundation!5e0!3m2!1sen!2sus!4v1644413811102!5m2!1sen!2sus' width={600} height={250} style={{border:"0"}} allowFullScreen loading='lazy'></iframe>
            </Row>
        </Container><br/>
        <Footer/>
    </>
    )
}
export default Home;